import React from "react";
import { useSelector } from "react-redux";
import { selectPemifData } from "../../../store/reducers/pemifReducer";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";

function FormSubmissionError() {
  const { errorMessage = '', validationErrorList=[] } = useSelector(selectPemifData);

  // Extract candidate number from the errorMessage
  const candidateMatch = errorMessage.match(/candidate\s*:\s*(\d+)/i);
  const candidateNumber = candidateMatch ? candidateMatch[1] : "unknown";

  // Function to format validation error messages
  const formatErrorMessage = (message) => {
    if (/role must be selected/i.test(message)) {
      return 'Please complete field "Role".';
    } else if (/invalid first event mark for activity (.+)/i.test(message)) {
      const activity = message.match(/invalid first event mark for activity (.+)/i)[1];
      return `Invalid first event/skill/position mark value for activity ${activity}.`;
    } else if (/first event must be selected for activity (.+)/i.test(message)) {
      const activity = message.match(/first event must be selected for activity (.+)/i)[1];
      return `First event/skill/position must be selected for activity ${activity}.`; 
    } else if (/second event must be selected for event (.+)/i.test(message)) {
      const eventMatch = message.match(/second event must be selected for event (.+)/i);
      const eventName = eventMatch && eventMatch[1] !== "null" ? eventMatch[1] : "the activity";
      return `Second event must be selected for ${eventName}.`;
    } else if (/second event mark must be filled/i.test(message)) {
      return `Invalid second event/skill/position mark value.`;
    }

    // Default case (if no transformation needed)
    return message.replace(/:/, "").replace("validation", "Validation");
  };


 // Construct the final error message
 const baseMessagePart1 = "There are errors in the details you have entered for one or more of your candidates.";
 const baseMessagePart2 = `Please review the following error for candidate ${candidateNumber}:`;


  return (
    <Box sx={{ color: "red" }}>
      <React.Fragment>
      <Typography variant="body2" sx={{ mt: 1, mb: -1 }}>
          {baseMessagePart1}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, mb: -1 }}>
          {baseMessagePart2}
        </Typography>
        <List sx={{ listStyleType: "disc", pl: 2 }}>
          {validationErrorList.map((item, index) => (
            <ListItem
              key={index}
              disableGutters
              sx={{ display: "list-item", mt: -1, mb: -3 }}
            >
              <ListItemText primary={formatErrorMessage(item.message)} />
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    </Box>
  );
}

export default FormSubmissionError;
