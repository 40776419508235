import React, { createContext, useMemo } from "react";

export const QualContext = createContext({
  qualification: [],
});

export const QualProvider = ({ children }) => {
  const contextValue = useMemo(
    () => ({
      qualification: [
        {
          qualificationId: [440, 159],
          qualificationName: "OCR Level 3 Advanced GCE",
          qualificationDisplayName: "AS/A Level visit arrangements",
        },
        {
          qualificationId: [1787],
          qualificationName: "OCR Level 1/Level 2 GCSE (9-1)",
          qualificationDisplayName: "GCSE Level visit arrangements",
        },
        {
          qualificationId: [97],
          qualificationName: "Entry Level Certificate",
          qualificationDisplayName: "Entry Level visit arrangements",
        },
      ],
      markSubmissionQual: [
        {
          qualificationId: [440],
          qualificationName: "OCR Level 3 Advanced GCE",
          qualificationDisplayName: "A Level (H555/05)",
          qualificationDisplayNameButton: ["A Level", <br />, "(H555/05)"]
        },
        {
          qualificationId: [159],
          qualificationName: "OCR Level 3 Advanced GCE",
          qualificationDisplayName: "AS Level (H155/04)",
          qualificationDisplayNameButton: ["AS Level", <br />, "(H155/04)"]
        },
        {
          qualificationId: [1787],
          qualificationName: "OCR Level 1/Level 2 GCSE (9-1)",
          qualificationDisplayName: "GCSE (J587/04)",
          qualificationDisplayNameButton: ["GCSE", <br />, "(J587/04)"]
        },
        {
          qualificationId: [97],
          qualificationName: "Entry Level Certificate",
          qualificationDisplayName: "Entry Level (R463)",
          qualificationDisplayNameButton: ["Entry Level", <br />, "(R463)"]
        },
      ],
    }),
    []
  );
  return (
    <QualContext.Provider value={contextValue}>
      <>{children}</>
    </QualContext.Provider>
  );
};
