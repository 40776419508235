import React from "react";
import { useMemo, useState } from 'react';

import { DataGrid } from "@mui/x-data-grid";
import "./marksubmission.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  FormControl,
  TextField, InputAdornment, Button, Typography, Select
} from "@mui/material";
import { MenuItem } from '@mui/material';

import {
  handleEntryChange,
  selectCandidates,
  updateCandidatesFromData,
  calculateMarks,
  calculateOverallMarkForGCSEAndEntry
} from "../../../store/reducers/candidateReducer";
import { useSelector, useDispatch } from "react-redux";
import { selectActivity } from "../../../store/reducers/activityReducer";
import {
  resetRestrictedActivity,
  selectRestrictedActivity,
} from "../../../store/reducers/restrictedActivityReducer";
import { checkStatus } from "./commonFuntions";
import { selectRole } from "../../../store/reducers/roleReducer";
import firstEventSlice, { selectFirstEvent } from "../../../store/reducers/eventReducer";
import {
  renderActivitiesRows,
  renderActivityTypeRows,
  renderEventRows,
  renderEvidenceRows,
  renderMarkRows,
  renderRolesRows,
  renderSexRows,
} from "./EntryLevelCell";
import { useAuth } from "react-oidc-context";
import { tableInputStyle, inputStyleSecondary } from "../../../utils/Theme";
import { selectExceptionActivity } from "../../../store/reducers/exceptionalActivityReducer";
import { useLocation } from "react-router-dom";
import { QualMapping } from "../../../utils/constants";
import { sortEvents } from "../../../utils/commonFunctions";

const EntryLevelTable = () => {
  const auth = useAuth();
  const acessToken = auth.user?.access_token;
  const dispatch = useDispatch();
  const { activities } = useSelector(selectActivity);
  const { candidates, selectedActivities } = useSelector(selectCandidates);
  const { roles } = useSelector(selectRole);
  const { noEvents } = useSelector(selectExceptionActivity);
  const {
    first_events,
    first_event_row_id,
    second_event_row_id,
    third_event_row_id,
    second_events,
    third_events,
    first_event_row_type,
    second_event_row_type,
    third_event_row_type,
  } = useSelector(selectFirstEvent);
  const restrictedActivity = useSelector(selectRestrictedActivity);
  const location = useLocation()
  let qualification =
    location.state?.name === QualMapping.ENTRY
      ? `ENTRY`
      : location.state?.name === QualMapping.GCSE
        ? `GCSE`
        : location.state?.name === QualMapping.ALEVEL
          ? `A`
          : `AS`;
  let rowData = candidates ? [...candidates] : []; 
  const rows = rowData.map((row, i) => {
    const baseSubRow = [
      {
        activity_type_name: "Individual",
        activityType: "entry_lvl_indiv_activities",
        ...row.entry_lvl_indiv_activities,
        sex: row.sex,
        candidate_number: row.candidate_number,
        candidate_name: row.candidate_name,
        restrictedActivities:
          restrictedActivity && restrictedActivity[i]
            ? restrictedActivity[i]
            : null,
      },
      {
        activity_type_name: "Free Choice One",
        activityType: "entry_lvl_free_choice_one_activities",
        ...row.entry_lvl_free_choice_one_activities,
        sex: row.sex,
        candidate_number: row.candidate_number,
        candidate_name: row.candidate_name,
        restrictedActivities:
          restrictedActivity && restrictedActivity[i]
            ? restrictedActivity[i]
            : null,
      },
      {
        activity_type_name: "Free Choice Two",
        activityType: "entry_lvl_free_choice_two_activities",
        ...row.entry_lvl_free_choice_two_activities,
        sex: row.sex,
        candidate_number: row.candidate_number,
        candidate_name: row.candidate_name,
        restrictedActivities:
          restrictedActivity && restrictedActivity[i]
            ? restrictedActivity[i]
            : null,
      },
      {
        activity_type_name: "Performance",
        activityType: "entry_lvl_perf_analysis",
        ...row.entry_lvl_perf_analysis,
        sex: row.sex,
        candidate_number: row.candidate_number,
        candidate_name: row.candidate_name,
        restrictedActivities:
          restrictedActivity && restrictedActivity[i]
            ? restrictedActivity[i]
            : null,
      },
    ];

    return {
      ...row,
      ...row.entry_lvl_team_activities,
      activity_type_name: "Team",
      activity_types: "Team, Individual, Free Choice, Free Choice 2, Performance",
      activityRows: [...baseSubRow],

    };
  });

  const resetFields = [
    'activity_id',
    'role',
    'first_event_id',
    'first_event_mark',
    'second_event_id',
    'second_event_mark',
    'third_event_id',
    'third_event_mark',
    'onsite_filmed_evidence_available_value'
  ]

  // Exclude user from selecting same activity or restricted activities (i.e. activities that cannot be combined)
  const includeActivity = (activity, row) => {
    let include = true;
    if (row.depth === 0) {
      // Top level row, check all subrows for potential duplicates
      row.subRows.forEach(x => {
        if (x.getValue('activity_id') == activity.activity_id)
          include = false;
        else if (activity.restricted_activities) {
          // Check for any restrictions
          activity.restricted_activities.forEach(y => {
            if (y == x.getValue('activity_id'))
              include = false;
          })
        }
      });
    }
    else {
      // Get parent, check parent and childrows for any duplicates
      const parent = row.getParentRow();
      if (parent.getValue('activity_id') == activity.activity_id)
        include = false;
      else if (activity.restricted_activities) {
        // Check for any restrictions
        activity.restricted_activities.forEach(y => {
          if (y == parent.getValue('activity_id'))
            include = false;
        })
      }

      parent.subRows.forEach(x => {
        if (x.index != row.index && x.getValue('activity_id') == activity.activity_id)
          include = false;
        else if (activity.restricted_activities) {
          // Check for any restrictions
          activity.restricted_activities.forEach(y => {
            if (y == x.getValue('activity_id'))
              include = false;
          })
        }
      });
    }
    return include;
  }

  const getFirstEventOptions = (params) => {
    return (params.row.getValue('sex') === "F" &&
      params.row.getValue('activity_id') &&
      params.row.getValue('role') &&
      (params.row.getValue('role') != "Leader") &&
      activities?.female_Activities &&
      activities?.female_Activities
      .filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]
      .first_events
      ? activities?.female_Activities
      .filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]
      .first_events.filter(x =>
        // Removing filter on role as role is nearly always NULL and there are no events filtered by role
        //((x.event_role_name == params.row.getValue('role')) &&
        (x.event_sex_restriction == "None" ||
         x.event_sex_restriction == "Female"))
        .map(x => ({ value: x.event_id, label: x.event_display_name }))
      : params.row.getValue('sex') === "M" &&
        params.row.getValue('role') &&
        (params.row.getValue('role') != "Leader") &&
        params.row.getValue('activity_id') &&
        activities?.male_Activities &&
        activities?.male_Activities
        .filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]
        .first_events
        ? activities?.male_Activities
        .filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]
        .first_events.filter(x =>
          // Removing filter on role as role is nearly always NULL and there are no events filtered by role
          //((x.event_role_name == params.row.getValue('role')) &&
          (x.event_sex_restriction == "None" ||
           x.event_sex_restriction == "Male"))
          .map(x => ({ value: x.event_id, label: x.event_display_name }))
        : []);
  }

  const getSecondEventOptions = (params) => {
    return (params.row.getValue('sex') === "F" &&
      params.row.getValue('activity_id') &&
      params.row.getValue('role') &&
      (params.row.getValue('role') != "Leader") &&
      params.row.getValue('first_event_id') &&
      activities?.female_Activities &&
      activities?.female_Activities
      .filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]
      ?.first_events?.filter(
        x => (x.event_id == params.row.getValue('first_event_id'))
      )[0]?.next_level_events
      ? activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]
      ?.first_events?.filter(
        x => (x.event_id == params.row.getValue('first_event_id'))
      )[0]
      ?.next_level_events?.filter(
        (x) =>
          x.event_sex_restriction === "None" ||
          x.event_sex_restriction == "Female"
      )
      ?.map(x => ({ value: x.event_id, label: x.event_display_name }))
      : params.row.getValue('sex') === "M" &&
        params.row.getValue('role') &&
        (params.row.getValue('role') != "Leader") &&
        params.row.getValue('activity_id') &&
        params.row.getValue('first_event_id') &&
        activities?.male_Activities &&
        activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id')))
        )[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]
        ?.next_level_events
        ? activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id')))
        )[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id'))
        )[0]?.next_level_events?.filter(
          (x) =>
            x.event_sex_restriction === "None" ||
            x.event_sex_restriction == "Male"
        )

        ?.map(x => ({ value: x.event_id, label: x.event_display_name }))
        : []);
  }

  const getThirdEventOptions = (params) => {
    return (params.row.getValue('sex') === "F" &&
      params.row.getValue('activity_id') &&
      params.row.getValue('role') &&
      (params.row.getValue('role') != "Leader") &&
      params.row.getValue('first_event_id') &&
      params.row.getValue('second_event_id') &&
      activities?.female_Activities &&
      activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events &&
      activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == params.row.getValue('second_event_id')))[0]?.next_level_events
      ? activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == params.row.getValue('second_event_id')))[0]?.next_level_events?.map(x => ({ value: x.event_id, label: x.event_display_name }))
      : params.row.getValue('sex') === "M" &&
        params.row.getValue('role') &&
        (params.row.getValue('role') != "Leader") &&
        params.row.getValue('activity_id') &&
        params.row.getValue('first_event_id') &&
        params.row.getValue('second_event_id') &&
        activities?.male_Activities &&
        activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events &&
        activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == params.row.getValue('second_event_id')))[0]?.next_level_events
        ? activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == params.row.getValue('second_event_id')))[0]?.next_level_events?.map(x => ({ value: x.event_id, label: x.event_display_name }))
        : []);
  }



  const resetMarks = (row, markNumber, includeSubRows) => {
    if (markNumber == 1) {
      row._valuesCache.first_event_mark = "";
      if (includeSubRows)
        row.subRows.forEach(x => { x._valuesCache.first_event_mark = "" })
    }

    if (markNumber <= 2) {
      row._valuesCache.second_event_mark = "";
      if (includeSubRows)
        row.subRows.forEach(x => { x._valuesCache.second_event_mark = "" })
    }

    if (markNumber <= 3) {
      row._valuesCache.third_event_mark = "";
      if (includeSubRows)
        row.subRows.forEach(x => { x._valuesCache.third_event_mark = "" })
    }

    // Update the activity mark
    row._valuesCache.total_for_activity = calculateMarks(row._valuesCache, { payload: { qual: "entryLevel" } });
    if (includeSubRows)
      row.subRows.forEach(x => { x._valuesCache.total_for_activity = calculateMarks(x._valuesCache, { payload: { qual: "entryLevel" } }); });
    // Update overall mark
    if (row.depth == 0)
      row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row._valuesCache, ...row.subRows.map(x => x._valuesCache)]);
    else
      row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row.getParentRow()._valuesCache, ...row.getParentRow().subRows.map(x => x._valuesCache)]);

  }


  const resetRow = (params, field) => {
    let found = false;
    resetFields.forEach(x => {
      if (found) {
        if (params.row.depth == 0)
          data[params.row.index][field] = "";
        else
          data[params.row.parentId].activityRows[params.row.index][field] = "";
      }
      else
        if (x == field)
          found = true;

    });

    //rowData[0].entry_lvl_team_activities.role = "FRED";
  }

  const [data, setData] = useState(rows)


  const getRowHeight = params => 200;

  const isDisabled = row =>
    row.getValue('sex') === "Unknown" ||
    row.original.candidate_attendance_status === "A" ||
    row.original.candidate_attendance_status === "W";

  const getNumber = (x) => {
    return isNaN(parseInt(x)) ? 0 : parseInt(x)
  }

  const onChangeEvent = (event, row, activities, id) => {
    if (row.depth == 0)
      data[row.index][event.name] = event.value;
    else
      data[row.parentId].activityRows[row.index][event.name] = event.value;

    // params.row._valuesCache.first_event_id = event.value;

    dispatch(
      handleEntryChange({
        event: { name: event.name, value: event.value },
        id: row.depth == 0 ? row.index : row.parentId,
        data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
        activityTypeCode: row.depth == 0 ? 'entry_lvl_team_activities' : data[row.parentId].activityRows[row.index].activityType,
        qual: "entryLevel"
      })
    );
  }

  const prepopulateEvents = (row) => {
    const acts = row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities;

    if (row.getValue('sex') && row.getValue('activity_id') && row.getValue("role")) {

      let firstLevelEvents = acts.filter(x => (x.activity_id === parseInt(row.getValue("activity_id"))))[0]?.first_events || [];

      // Check and populate first level events
      if (firstLevelEvents?.length === 1) {
        if (!row.getValue("first_event_id") || (row.getValue("first_event_id") !== firstLevelEvents[0].event_id)) {
          // Populate first level event with new value
          row._valuesCache.first_event_id = firstLevelEvents[0].event_id;
          onChangeEvent(
            {
              name: 'first_event_id',
              value: row._valuesCache.first_event_id
            },
            row,
            activities,
            row.id
          );
        }
      }

      let secondLevelEvents = firstLevelEvents.length && firstLevelEvents.filter(x => (x.event_id === row.getValue("first_event_id")))[0]?.next_level_events || [];

      // Check and populate second level events
      if (row.getValue("first_event_id") && secondLevelEvents?.length === 1) {
        if (row.getValue('second_event_id') !== secondLevelEvents[0].event_id) {
          // Populate second level event with new value
          row._valuesCache.second_event_id = secondLevelEvents[0].event_id;
          onChangeEvent({
            name: 'second_event_id',
            value: row._valuesCache.second_event_id
          }, row, activities, row.id);
        }
      }

      let thirdLevelEvents = secondLevelEvents.length && secondLevelEvents.filter(x => (x.event_id === row.getValue("second_event_id")))[0]?.next_level_events || [];

      // Check and populate third level events
      if (row.getValue("second_event_id") && thirdLevelEvents?.length === 1) {
        if (row.getValue('third_event_id') !== thirdLevelEvents[0].event_id) {
          // Populate third level event with new value
          row._valuesCache.third_event_id = thirdLevelEvents[0].event_id;
          onChangeEvent({
            name: 'third_event_id',
            value: row._valuesCache.third_event_id
          }, row, activities, row.id);
        }
      }
    }
  };
  const restoreMissingEventIds = (row) => {
      let activityType = ''
      if(row.getValue("activity_type_name") === 'Team')
        activityType = 'entry_lvl_team_activities'
      else if(row.getValue("activity_type_name") === 'Individual')
        activityType = 'entry_lvl_indiv_activities'
      else if(row.getValue("activity_type_name") === 'Free Choice One')
        activityType = 'entry_lvl_free_choice_one_activities'
      else if(row.getValue("activity_type_name") === 'Free Choice Two')
        activityType = 'entry_lvl_free_choice_two_activities'
      else if(row.getValue("activity_type_name") === 'Performance')
        activityType = 'entry_lvl_perf_analysis'
    // Required to fix a weird issue where the event field is being cleared when it's been prepopulated and your tabbing between the gcseOptions
    const candidate = candidates.filter(x => x.candidate_number == row.getValue("candidate_number"))[0];
    if ( candidate[activityType].first_event_id)
      row._valuesCache.first_event_id = candidate[activityType].first_event_id;
    // Re-populate the event Id values if not matching the data
    if ( candidate[activityType].second_event_id)
      row._valuesCache.second_event_id = candidate[activityType].second_event_id;
    if ( candidate[activityType].third_event_id)
      row._valuesCache.third_event_id = candidate[activityType].third_event_id;
  }
  const columns = [
    {
      accessorKey: "candidate_number",
      header: "Candidate number",
      enableEditing: false,
      size: 110,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: (row.depth != 0) ? "candidate_number_childrow" : "candidate_number"
      }),
      Cell: params => {
        // Render the candidate number but make it invisible for childrows, this is to ensure the search still returns the info
        if (params.row.depth != 0)
          return <Typography sx={{ display: 'none' }}>{params.cell.getValue()}</Typography>;
        else
          return <Typography>{params.cell.getValue()}</Typography>;
      },
    },
    {
      accessorKey: "candidate_name",
      header: "Candidate name",
      enableEditing: false,
      size: 180,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: (row.depth != 0) ? "candidate_name_childrow" : "candidate_name"
      }),
      Cell: params => {
        // Render the candidate number but make it invisible for childrows, this is to ensure the search still returns the info
        if (params.row.depth != 0)
          return <Typography sx={{ display: 'none' }}>{params.cell.getValue()}</Typography>;
        else
          return <Typography>{params.cell.getValue()}</Typography>;
      },
    },
    {
      accessorKey: "sex",
      header: "Sex assigned at birth",
      size: 110,
      enableSorting: false,
      enableEditing: false,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: row.depth != 0 ? "sex_childrow" : "sex",
      }),
      Cell: (params) => {
        return (
          <Typography>
            {params.row.depth == 0
              ? params.cell.getValue() === "F"
                ? "Female"
                : params.cell.getValue() === "M"
                  ? "Male"
                  : ""
              : ""}
          </Typography>
        );
      },
    },
    {
      accessorKey: "activity_type_name",
      enableSorting: false,
      header: "Type of activity",
      enableEditing: false,
      tabindex: -1,
      size: 120,
      Cell: (params) => {
        return (
          <Typography>
            {params.cell.getValue() == "Performance"
            ? "AP Task"
          : params.cell.getValue()
      }
          </Typography>
        );
      },
    },
    {
      accessorKey: "activity_id",
      enableSorting: false,
      header: "Activity",
      size: 150,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          const newValue = event.target.value;

          // Clear the role
          if (row.depth === 0) {
            data[row.index].role = row._valuesCache.role = "";
          } else {
            data[row.parentId].activityRows[row.index].role = row._valuesCache.role = "";
          }

          resetMarks(row, 1, false);

          dispatch(
            handleEntryChange({
              event: {
                name: column.id,
                value: newValue,
              },
              data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              id: row.depth === 0 ? row.index : row.parentId,
              activityTypeCode: row.depth === 0 ? 'entry_lvl_team_activities' : data[row.parentId].activityRows[row.index].activityType,
              qual: "entryLevel",
            })
          );

          // Update value in table
          if (row.depth === 0) {
            data[row.index].activity_id = row._valuesCache.activity_id = newValue;
          } else {
            data[row.parentId].activityRows[row.index].activity_id = row._valuesCache.activity_id = newValue;
          }


        };
        const selectedActIds = (selectedActivities && Object.keys(selectedActivities).length && selectedActivities[qualification].length) ? selectedActivities[qualification].map(x => parseInt(x.activity_id)) : [];
        const actlist = (row.getValue('sex') === "M"
          && activities?.male_Activities
          ? activities?.male_Activities
            .filter(x => ((x.activity_type == row.getValue("activity_type_name")
              || row.getValue("activity_type_name") == "Free Choice One"
              || row.getValue("activity_type_name") == "Free Choice Two")
              && (includeActivity(x, row))))
            .map(x => ({ value: x.activity_id, label: x.activity_name })) :
          row.getValue('sex') === "F"
            && activities?.female_Activities
            ? activities?.female_Activities
              .filter(x => ((x.activity_type == row.getValue("activity_type_name")
                || row.getValue("activity_type_name") == "Free Choice One"
                || row.getValue("activity_type_name") == "Free Choice Two")
                && (includeActivity(x, row))))
              .map(x => ({ value: x.activity_id, label: x.activity_name }))
            : []).filter(x => (selectedActIds.length == 0 ? true : selectedActIds.includes(parseInt(x.value)))) ||[];;

        return <Select
          sx={tableInputStyle}
          value={cell.getValue()}
          onChange={onChange}
          disabled={isDisabled(row) || row.original.activity_type_name == "Performance"}
        >
          {(actlist ||[]).map(activity => {
            return (
              <MenuItem key={activity.value} value={activity.value}>
                {activity.label}
              </MenuItem>
            );
          })
          }
        </Select>;

      },


      Cell: params => {
        return (<Typography>{params.cell.getValue()}</Typography>
        )
      },
    },
    {
      accessorKey: "role",
      header: "Role",
      enableSorting: false,
      enableGlobalFilter: false,
      size: 110,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          if (row.depth == 0)
            data[row.index].role = event.target.value
          else
            data[row.parentId].activityRows[row.index].role = event.target.value

          resetMarks(row, 1, false);

          // Update the cached value here so that the check for pre-populating has the latest value
          row._valuesCache.role = event.target.value;

          dispatch(
            handleEntryChange({
              event: { name: column.id, value: event.target.value },
              id: row.depth == 0 ? row.index : row.parentId,
              data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              activityTypeCode: row.depth == 0 ? 'entry_lvl_team_activities' : data[row.parentId].activityRows[row.index].activityType,
              qual: "entryLevel"
            })
          );

          // Do the prepopulate after the change event, the change event is resetting fields and preventing the prepopulate
          prepopulateEvents(row, "role");

        };

        return <Select
          sx={{ ...tableInputStyle, width: "100%" }}
          value={
            cell.getValue()
          }
          onChange={onChange}
          disabled={isDisabled(row) || row.original.activity_type_name == "Performance"}
        >
          {roles &&
            roles.map(role => (
              <MenuItem key={role.role_name} value={role.role_name}>
                {role.role_name}
              </MenuItem>
            ))}
        </Select>;
      },
      editVariant: 'select',
      editSelectOptions: (params) => { return (roles ? roles.map(x => ({ value: x.role_name, label: x.role_name })) : []) },
      muiEditTextFieldProps: (params) => ({
        select: true,
        disabled: isDisabled(params.row) || params.row.original.activity_type_name == "Performance",
        onChange: (event) => {
          if (params.row.depth == 0)
            data[params.row.index].role = event.target.value
          else
            data[params.row.parentId].activityRows[params.row.index].role = event.target.value

          // Update the cached value here so that the check for pre-populating has the latest value

          params.row._valuesCache.role = event.target.value;
          prepopulateEvents(params.row);

          dispatch(
            handleEntryChange({
              event: { name: event.target.name, value: event.target.value },
              id: params.row.depth == 0 ? params.row.index : params.row.parentId,
              data: params.row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              activityTypeCode: params.row.depth == 0 ? 'entry_lvl_team_activities' : data[params.row.parentId].activityRows[params.row.index].activityType,
              qual: "entryLevel"
            })
          );

        },
      }),
    },
    {
      accessorKey: "first_event_id",
      header: "First event/skill/ position",
      size: 150,
      enableSorting: false,
      editVariant: 'select',
      editSelectOptions: (params) => {
        return sortEvents(getFirstEventOptions(params));
      },
      muiEditTextFieldProps: (params) => {
        return {
          id: "first_" + params.row.id,
          select: true,
          value: getFirstEventOptions(params).length === 1
            ? getFirstEventOptions(params)[0].value
            : params.row.getValue("first_event_id"),
          disabled: !params.row.getValue('sex') ||
            !params.row.getValue('activity_id') || params.row.getValue("role") === "Leader" ||
            !params.row.getValue('role') ||
            (params.row.getValue('sex') === "F" && activities?.female_Activities && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events) ||
            (params.row.getValue('sex') === "M" && activities?.male_Activities && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events) ||
            (params.row.getValue('sex') === "F" && activities?.female_Activities && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events.filter(x => ((x.event_role_name == params.row.getValue('role')) && (x.event_sex_restriction == "None" || x.event_sex_restriction == "Female")))) ||
            (params.row.getValue('sex') === "M" && activities?.male_Activities && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0].first_events.filter(x => ((x.event_role_name == params.row.getValue('role')) && (x.event_sex_restriction == "None" || x.event_sex_restriction == "Male")))),

          onChange: (event) => {
            params.row._valuesCache.first_event_id = event.target.value;

            resetMarks(params.row, 1, false);
            onChangeEvent(event.target, params.row, activities, 1)
            prepopulateEvents(params.row);

          }
        }
      }
    },
    {
      accessorKey: "first_event_mark",
      header: "Mark",
      size: 85,
      enableGlobalFilter: false,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          restoreMissingEventIds(row)
          // Dirty hack to prevent scrolling the table changing the value of this field
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 20)
            event.target.value = 20;
          if (event.target.value < 0)
            event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleEntryChange({
              event: { name: column.id, value: event.target.value },
              data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode: row.depth == 0 ? 'entry_lvl_team_activities' : data[row.parentId].activityRows[row.index].activityType,
              qual: "entryLevel"
            })
          );

          // Update the activity mark
          row._valuesCache.total_for_activity = calculateMarks(row._valuesCache, { payload: { qual: "entryLevel" } });
          // Update overall mark
          if (row.depth == 0)
            row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row._valuesCache, ...row.subRows.map(x => x._valuesCache)]);
          else
            row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row.getParentRow()._valuesCache, ...row.getParentRow().subRows.map(x => x._valuesCache)]);

        }

        return <TextField onChange={onChange}
          value={cell.getValue()}
          type="number"
          sx={{
            ...{ ...tableInputStyle },
            width: "100%"
          }}
          disabled={(!row.getValue('sex') ||
            !row.getValue('activity_id')) && (row.getValue('activity_type_name') != "Performance")}
          // CASS-997 - Hide values and placeholder text when disabled
          placeholder={(!row.getValue('sex') ||
            !row.getValue('activity_id')) && (row.getValue('activity_type_name') != "Performance")
            ? ""
            : "Mark"
          }

        />;
      }
    },
    {
      accessorKey: "second_event_id",
      header: "Second event/skill/ position",
      size: 150,
      enableSorting: false,
      editVariant: 'select',
      editSelectOptions: (params) => {
        return sortEvents(getSecondEventOptions(params));
      },
      muiEditTextFieldProps: (params) => ({
        //    select: true,
        id: "second_" + params.row.id,
        value: getSecondEventOptions(params).length === 1 ? getSecondEventOptions(params)[0].value : params.row.getValue("second_event_id"),
        disabled: !params.row.getValue('sex') ||
          !params.row.getValue('activity_id') ||
          !params.row.getValue('role') || params.row.getValue("role") === "Leader" ||
          !params.row.getValue('first_event_id') ||
          (params.row.getValue('sex') === "F" && activities?.female_Activities && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events) ||
          (params.row.getValue('sex') === "M" && activities?.male_Activities && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events) ||
          (params.row.getValue('sex') === "M" && activities?.male_Activities && (!activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events || !activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events)) ||
          (params.row.getValue('sex') === "F" && activities?.female_Activities && (!activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events || !activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events))
        ,

        onChange: (event) => {
          resetMarks(params.row, 2, false);
          onChangeEvent(event.target, params.row, activities, 2)
          prepopulateEvents(params.row);

        }

      }),

    },
    {
      accessorKey: "second_event_mark",
      header: "Mark",
      size: 85,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          restoreMissingEventIds(row)
          // Dirty hack to prevent scrolling the table changing the value of this field
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 20)
            event.target.value = 20;
          if (event.target.value < 0)
            event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleEntryChange({
              event: { name: column.id, value: event.target.value },
              data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode: row.depth == 0 ? 'entry_lvl_team_activities' : data[row.parentId].activityRows[row.index].activityType,
              qual: "entryLevel"
            })
          );

          // Update the activity mark
          row._valuesCache.total_for_activity = calculateMarks(row._valuesCache, { payload: { qual: "entryLevel" } });
          // Update overall mark
          if (row.depth == 0)
            row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row._valuesCache, ...row.subRows.map(x => x._valuesCache)]);
          else
            row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row.getParentRow()._valuesCache, ...row.getParentRow().subRows.map(x => x._valuesCache)]);

        }

        return <TextField onChange={onChange}
          value={cell.getValue()}
          type="number"
          sx={{
            ...{ ...tableInputStyle },
            width: "100%"
          }}
          disabled={!row.getValue('sex') ||
            !row.getValue('activity_id') ||
            !row.getValue('role') ||
            (row.getValue('role') && row.getValue('role') == "Leader") ||
            !row.getValue('first_event_id') ||
            !row.getValue('second_event_id') ||
            (row.getValue('sex') === "F" && activities?.female_Activities && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events) ||
            (row.getValue('sex') === "M" && activities?.male_Activities && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events) ||
            (row.getValue('sex') === "M" && activities?.male_Activities && (!activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events || !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events)) ||
            (row.getValue('sex') === "F" && activities?.female_Activities && (!activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events || !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events))
          }
          // CASS-997 - Hide values and placeholder text when disabled
          placeholder={(row.getValue('activity_type_name') == "Performance" ||
            !row.getValue('sex') ||
            !row.getValue('activity_id') ||
            !row.getValue('role') ||
            (row.getValue('role') && row.getValue('role') == "Leader") ||
            !row.getValue('first_event_id') ||
            !row.getValue('second_event_id') ||
            (row.getValue('sex') === "F" && activities?.female_Activities && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events) ||
            (row.getValue('sex') === "M" && activities?.male_Activities && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events) ||
            (row.getValue('sex') === "M" && activities?.male_Activities && (!activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events || !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events)) ||
            (row.getValue('sex') === "F" && activities?.female_Activities && (!activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events || !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events))
            ? ""
            : "Mark"
          )}

        />;
      },
    },
    {
      accessorKey: "third_event_id",
      header: "Third event/skill/ position",
      size: 150,
      enableSorting: false,
      editVariant: 'select',
      editSelectOptions: (params) => {
        return sortEvents(getThirdEventOptions(params));
      },
      muiEditTextFieldProps: (params) => ({
        //    select: true,
        id: "third_" + params.row.id,
        value: getThirdEventOptions(params).length === 1 ? getThirdEventOptions(params)[0].value : params.row.getValue("third_event_id"),
        disabled: !params.row.getValue('sex') ||
          !params.row.getValue('activity_id') ||
          !params.row.getValue('role') ||
          (params.row.getValue('role') && params.row.getValue('role') == "Leader") ||
          !params.row.getValue('first_event_id') ||
          !params.row.getValue('second_event_id') ||
          (params.row.getValue('sex') === "F" && !activities?.female_Activities) ||
          (params.row.getValue('sex') === "M" && !activities?.male_Activities) ||
          (params.row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events) ||
          (params.row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events) ||
          (params.row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events) ||
          (params.row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events) ||
          (params.row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == params.row.getValue('second_event_id')))[0]?.next_level_events) ||
          (params.row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(params.row.getValue('activity_id'))))[0]?.first_events.filter(x => (x.event_id == params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == params.row.getValue('second_event_id')))[0]?.next_level_events)
        ,
        onChange: (event) => {
          resetMarks(params.row, 2, false);
          onChangeEvent(event.target, params.row, activities, 3)
        }
      }),

    },
    {
      accessorKey: "third_event_mark",
      header: "Mark",
      size: 85,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          restoreMissingEventIds(row)
          // Dirty hack to prevent scrolling the table changing the value of this field
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 20)
            event.target.value = 20;
          if (event.target.value < 0)
            event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleEntryChange({
              event: { name: column.id, value: event.target.value },
              data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode: row.depth == 0 ? 'entry_lvl_team_activities' : data[row.parentId].activityRows[row.index].activityType,
              qual: "entryLevel"
            })

          );

          // Update the activity mark
          row._valuesCache.total_for_activity = calculateMarks(row._valuesCache, { payload: { qual: "entryLevel" } });
          // Update overall mark
          if (row.depth == 0)
            row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row._valuesCache, ...row.subRows.map(x => x._valuesCache)]);
          else
            row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row.getParentRow()._valuesCache, ...row.getParentRow().subRows.map(x => x._valuesCache)]);

        }

        return <TextField onChange={onChange}
          value={cell.getValue()}
          type="number"
          sx={{
            ...{ ...tableInputStyle },
            width: "100%"
          }}
          disabled={(row.getValue('activity_type_name') == "Performance" ||
            !row.getValue('sex') ||
            !row.getValue('activity_id') ||
            !row.getValue('role') ||
            (row.getValue('role') && row.getValue('role') == "Leader") ||
            !row.getValue('first_event_id') ||
            !row.getValue('second_event_id') ||
            !row.getValue('third_event_id') ||
            (row.getValue('sex') === "F" && !activities?.female_Activities) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities) ||
            (row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events) ||
            (row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events) ||
            (row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == row.getValue('second_event_id')))[0]?.next_level_events) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == row.getValue('second_event_id')))[0]?.next_level_events)
          )}
          // CASS-997 - Hide values and placeholder text when disabled
          placeholder={(row.getValue('activity_type_name') == "Performance" ||
            !row.getValue('sex') ||
            !row.getValue('activity_id') ||
            !row.getValue('role') ||
            (row.getValue('role') && row.getValue('role') == "Leader") ||
            !row.getValue('first_event_id') ||
            !row.getValue('second_event_id') ||
            !row.getValue('third_event_id') ||
            (row.getValue('sex') === "F" && !activities?.female_Activities) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities) ||
            (row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events) ||
            (row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events?.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events) ||
            (row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == row.getValue('second_event_id')))[0]?.next_level_events) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id === parseInt(row.getValue('activity_id'))))[0]?.first_events.filter(x => (x.event_id == row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id == row.getValue('second_event_id')))[0]?.next_level_events)
            ? ""
            : "Mark"
          )}
        />;
      },

    },
    {
      accessorKey: "total_for_activity",
      header: "Total activity mark",
      size: 85,
      enableSorting: false,
      enableEditing: false,
      Cell: (params) => {
        return (<Typography>{params.cell.getValue()}</Typography>)
      },

    },
    {
      accessorKey: "overall_mark",
      header: "Overall activity mark",
      size: 85,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: (row.depth != 0) ? "overallmark_childrow" : "overallmark"
      }),
      enableEditing: false,
      enableSorting: false,
      Cell: params => {
        if (params.row.depth == 0)
          params.row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([params.row._valuesCache, ...params.row.subRows.map(x => x._valuesCache)]);
        else
          params.row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([params.row.getParentRow()._valuesCache, ...params.row.getParentRow().subRows.map(x => x._valuesCache)]);

        return (<Typography>{
          params.row.original.candidate_attendance_status == "W" ? "Withdrawn" :
            params.row.original.candidate_attenance_status == "A" ? "Not Available" :
              !params.row.parentId ? params.cell.getValue() : ""
        }</Typography>)
      },

    }

  ];

  const table = useMaterialReactTable({
    columns,
    data,
    filterFns: {
      numberandnameonlyfn: (row, id, filterValue) =>
        id === "candidate_number" || id === "candidate_name"
          ? row.getValue(id).indexOf(filterValue.toUpperCase()) >= 0
          : false,
    },
    enableColumnPinning: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnResizing: true,
    // CASS-1045 : Sorting of columns includes third state which confuses users
    enableSortingRemoval: false,
    // CASS-1049 : Remove toggle density from all tableInputStyle
    enableDensityToggle: false,
    enableHiding: false,
    layoutMode: 'grid-no-grow', //constant column widths
    initialState: {
      sorting: [{ id: "candidate_number", desc: false }],
      columnPinning: { left: ["candidate_number", "candidate_name", "sex", "activity_type_name", "activity_id", "role"] },
      expanded: true,
      density: 'compact'
    },
    enableExpandAll: false, //hide expand all double arrow in column header
    enableExpanding: true,
    filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
    getSubRows: (row) => row.activityRows, //default
    enableEditing: true,
    enableKeyboardShortcuts: false, // With our custom UI elements, set this to false to prevent underlying table cells getting focus.
    editDisplayMode: 'table',
    muiTableProps: {
      className: 'EntryLevelTable'
    },
    muiTableBodyRowProps: ({ row }) => {
      return (row.original.candidate_attendance_status == "W" || row.original.candidate_attendance_status == "A" ? {
        //conditionally style expanded rows
        sx: {
          backgroundColor: '#ccc',

        },
      } : {}, { hover: false })
    },
    muiTableBodyCellProps: { sx: { fontSize:'0.8em', paddingLeft: '5px', padding: 0 } },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#8884b4 ",
        "&:before": {
          backgroundColor: "#8884b4 !important",
        },
      },
    },
  });

  return (
    // <div style={{ height: 900, width: "100%" }}>
    <MaterialReactTable table={table} />
    // <DataGrid
    //   sx={{
    //     "& .MuiDataGrid-cellContent": {
    //       textWrap: "wrap",
    //     },
    //   }}
    //   rows={rows}
    //   columns={columns}
    //   initialState={{
    //     pagination: {
    //       paginationModel: { page: 0, pageSize: 10 },
    //     },
    //   }}
    //   autoHeight
    //   checkboxSelection={false}
    //   onSelectionModelChange={() => {}}
    //   getRowHeight={getRowHeight}
    //   disableColumnMenu
    // />
    // </div>
  );
};

export default EntryLevelTable;
