import React from 'react';
import StepActionsMarksSubmission from "./StepActionsMarksSubmission";
import { Typography, Box,Link } from "@mui/material";
import {  useLocation } from "react-router-dom";
import { QualMapping } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectPemifData } from "../../store/reducers/pemifReducer";

export default function Success() {
  const location = useLocation();
  const { session_name, session_year } = useSelector(selectPemifData);
  return (
    <>
      <Box sx={{ mt: 10, mb: 10 }}>
        <Typography variant="h6" sx={{ py: 4, fontWeight: "bold" }}>
          Form submitted successfully
        </Typography>

        {location.state?.name === QualMapping.ALEVEL ||
        location.state?.name === QualMapping.ASLEVEL ? (
          <Typography>
            Thank you for submitting your activity marks for the{" "}
            {`${session_name} ${session_year}`} series. Please make sure you
            send all your off-site filmed evidence and your sample of on-site
            filmed evidence (minimum eight candidate performances across three
            activities) to OCR. If you need to amend any details, please contact{" "}
           {/* <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link> */}
            <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
          </Typography>
        ) : location.state?.name === QualMapping.GCSE ? (
          <Typography>
            Thank you for submitting your activity marks for the{" "}
            {`${session_name} ${session_year}`} series. Please make sure you
            send all your off-site filmed evidence to OCR. If you need to amend
            any details, please contact{" "}
           {/* <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link> */}
            <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
          </Typography>
        ) : (
          <Typography>
            Thank you for submitting your activity marks for the{" "}
            {`${session_name} ${session_year}`} series. If you need to amend any
            details, please contact{" "}
           {/* <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link> */}
            <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
          </Typography>
        )}
      </Box>
      <StepActionsMarksSubmission isSuccess={true} />
    </>
  );
}
