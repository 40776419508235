import { Typography } from "@mui/material";
import React from 'react';

const CommonText = ({ level, indicator }) => {
  if (level === "ENTRY")
    return (
      <>
        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          Please list the approximate number of candidates being assessed in
          each of the activities below.
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          You must include every activity assessed.
        </Typography>
        {indicator === "Visiting" ? (
          <Typography variant="subtitle2" sx={{ mt: 1, mb: 1 }}>
            Please tell us if the activity can be moderated live, or if filmed
            evidence will be provided.
          </Typography>
        ) : (
          <Typography variant="subtitle2" sx={{ mt: 1, mb: 1 }}>
            Please tell us any activities you will be providing filmed evidence
            for.
          </Typography>
        )}
      </>
    );
  else if (level === "GCSE")
    return (
      <>
        <Typography variant="subtitle2" sx={{ mt: 1, mb: 1 }}>
          Please list the approximate number of candidates being assessed in
          each of the activities below.
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, mb: 1 }}>
          All off-site activities must be filmed. These have been already ticked
          for clarification.
        </Typography>
        {indicator && indicator === "Y" && (
          <Typography variant="subtitle2" sx={{ mt: 1, mb: 3 }}>
            Use the final two columns to tell us the number of extra males or
            females that could be available if extra participants are needed to
            make up teams for moderation
          </Typography>
        )}
      </>
    );
  else
    return (
      <>
        <Typography variant="subtitle2" sx={{ mt: 1, mb: 1 }}>
          {`Please list the approximate number of ${level} Level candidates being assessed
          in each of the activities below.`}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1, mb: 1 }}>
          All off-site activities must be filmed, so these are automatically
          ticked in the 'Filmed' column. In the same column, please tick which
          on-site activities are being filmed.
        </Typography>
        {indicator && indicator === "Y" && (
          <Typography variant="subtitle2" sx={{ mt: 1, mb: 3 }}>
            Use the final two columns to tell us the number of extra males or
            females that could be available if extra participants are needed to
            make up teams for moderation
          </Typography>
        )}
      </>
    );
};
export default CommonText;
