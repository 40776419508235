import { Box } from "@mui/system";
import React from "react";
import banner from "../../assets/Service-Image.jpg";

export const BannerImage = () => {
  const imageStyle = {
    width: "100%",
    objectFit: "cover",
    height: "100%",
    objectPosition: "top"
  };
  return (
    <Box sx={{ width: "100%", height: "45vh", padding: 0 }} data-testid="banner-box">
      <img style={imageStyle} src={banner} alt="landing page Banner" />
    </Box>
  );
};
