import { createContext, useMemo, useState, useEffect } from "react";
import { useAuthRequest } from "../api/apis";
import React from 'react';

export const UserContext = createContext({
  user: null,
  centre_name: "",
  centre_number: "",
  bpid: "",
  roles: [],
  error: "",
  lastSelectedOrg: "",
  businessStream: [],
  setSelectedCentre() {},
});

export function UserContextProvider({ children }) {
  const [centre_name, setCentreName] = useState("");
  const [centre_number, setCentreNumber] = useState("");
  const [bpid, setBpid] = useState("");
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(false);
  const [businessStream, setBusinessStream] = useState([]);
  const [lastSelectedOrg, setLastSelectedOrg] = useState("");
  //get User info
  const { result , loading } = useAuthRequest({
    url: process.env.REACT_APP_IDENTITY_USERINFO_URL,
    method: "GET",
  });
  //get details
  const getCenterName = centreNum => {
    const temp = result?.orgs.filter(
      org => org?.sourceSystems[0]?.orgId === centreNum
    );
    return temp[0]?.name;
  };
  const getCenterBpid = centreNum => {
    const temp = result?.orgs.filter(
      org => org?.sourceSystems[0]?.orgId === centreNum
    );
    return temp[0]?.bpid;
  };
  const getCentreRoles = bpid => {
    const temp = result?.roles.filter(role => role?.bpid === bpid);
    return temp.length ? temp[0]?.roleNames : [];
  };

  const getBusinessStreams = cnuber => {
    let businessSreams = [];
    if (bpid) {
      const filteredStreamsOnBpid = result?.orgs.filter(
        org => org?.bpid === bpid
      );
      businessSreams = filteredStreamsOnBpid[0].productGroup;
    }
    if (businessSreams?.length < 0) {
      const filterStreamOnCenter = result?.orgs.filter(
        org => org?.sourceSystems[0]?.orgId === cnuber
      );
      businessSreams = filterStreamOnCenter[0].productGroup;
    }
    return businessSreams;
  };
  //useEffect
  useEffect(() => {
    if (result?.lastSelectedOrgNCN) {
      setCentreNumber(result?.lastSelectedOrgNCN);
      setLastSelectedOrg(result?.lastSelectedOrgNCN);
    } else {
      const temp =
        result?.orgs[0]?.sourceSystems?.length > 0
          ? result?.orgs[0]?.sourceSystems[0]?.orgId
          : result?.orgs[0]?.bpid;
      if (temp) {
        setLastSelectedOrg(result?.lastSelectedOrgNCN);
        setCentreNumber(temp);
      }
    }
  }, [result]);

  useEffect(() => {
    centre_number && setCentreName(getCenterName(centre_number));
    centre_number && setBpid(getCenterBpid(centre_number));
    centre_number && setBusinessStream(getBusinessStreams(centre_number));
  }, [centre_number, bpid]);

  useEffect(() => {
    bpid && setRoles(getCentreRoles(bpid));
  }, [bpid]);

  const user = result;
  const contextValue = useMemo(
    () => ({
      centre_name,
      centre_number,
      user,
      setCentreNumber,
      setError,
      error,
      roles,
      businessStream,
      lastSelectedOrg,
      loading
    }),
    [
      centre_name,
      centre_number,
      roles,
      user,
      setCentreNumber,
      setError,
      error,
      businessStream,
      lastSelectedOrg,
      loading
    ]
  );
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}